import ajax from '../assets/uilt/ajax';

let orgInfoApply = {
  /**根据ID查询申请记录 */
  getById: req => { return ajax.get({ url: "/back/orgInfoApply/get-by-id", param: req.param, success: req.success }) },
  /**分页查询申请记录 */
  getPage: req => { return ajax.get({ url: "/back/orgInfoApply/get-page", param: req.param, success: req.success }) },

  /**增加申请记录(渠道充值、提现) */
  addOrgInfoApply: req => { return ajax.post({ url: "/back/orgInfoApply/add-orgInfoApply", param: req.param, success: req.success }) },
  /**审核渠道充值、提现 */
  disposeOrgInfoApply: req => { return ajax.post({ url: "/back/orgInfoApply/dispose-orgInfoApply", param: req.param, success: req.success }) },
}

export default orgInfoApply;