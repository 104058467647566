<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('orgName')">
          <el-select v-model="queryForm.orgInfoCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.orgInfoCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="0"></el-option>
            <el-option :label="$t('getApproved')" value="1"></el-option>
            <el-option :label="$t('notApproved')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="orgName+orgCode" fixed="left" :label="$t('applyOrg')" width="200">
          <template slot-scope="scope">
            {{scope.row.orgName}}({{scope.row.orgInfoCode}})
          </template>
        </el-table-column>
        <el-table-column prop="type" :label="$t('applicationType')" width="210">
          <template slot-scope="scope">
            <span v-if="scope.row.type==0" class="text-primary">{{$t('transferRechargeApply')}}</span>
            <span v-if="scope.row.type==1" class="text-success">{{$t('cashRechargeApply')}}</span>
            <span v-if="scope.row.type==2" class="text-danger">{{$t('withdrawDepositApply')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyAmount" :label="$t('applyAmount')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.applyAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('checkStatus')" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-primary">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('getApproved')}}</span>
            <span v-if="scope.row.status==2" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyCode" :label="$t('applyCode')" width="160"></el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="applyTime" :label="$t('applyTime')" width="200"></el-table-column>
        <el-table-column prop="applyUser" :label="$t('applyUser')" width="160"></el-table-column>
        <el-table-column prop="bankName" :label="$t('bankName')" width="160"></el-table-column>
        <el-table-column prop="bankTransferserial" :label="$t('bankTransferserial')" width="220"></el-table-column>
        <el-table-column prop="transfeDate" :label="$t('transfeDate')" width="200"></el-table-column>
        <el-table-column prop="orgInfoId" :label="$t('orgId')" width="160"></el-table-column>
        <el-table-column prop="transactionCode" :label="$t('transactionId')" width="200"></el-table-column>
        <el-table-column prop="transactionInfoId" :label="$t('transactionInfoId')" width="160"></el-table-column>
        <el-table-column prop="verifyTime" :label="$t('verifyTime')" width="200"></el-table-column>
        <el-table-column prop="verifyUser" :label="$t('auditor')" width="160"></el-table-column>
        <el-table-column prop="verifyRemark" :label="$t('checkRemark')" width="160">
          <template slot-scope="scope">
            <div class="overstep-hidden">{{scope.row.verifyRemark}}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('extend')" fixed="right" width="160">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0" size="mini" @click="openCheckApply(scope.row)" type="primary">{{$t('check')}}</el-button>
            <el-button v-else size="mini" disabled>{{$t('check')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t(applyTitle)" :visible.sync="isCheckApply" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="checkForm" :rules="rules" ref="checkForm">
        <el-divider content-position="left">{{$t('applyInfo')}}</el-divider>
        <el-form-item :label="$t('applyAmount')">
          <el-input size="medium" v-model="applyForm.applyAmount" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('bankName')" prop="bankName">
          <el-input size="medium" v-model="applyForm.bankName" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('bankTransferserial')" prop="bankTransferserial">
          <el-input size="medium" v-model="applyForm.bankTransferserial" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('transfeDate')" prop="transfeDate">
          <el-input size="medium" v-model="applyForm.transfeDate" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('applayRemark')" prop="remark">
          <el-input type="textarea" size="medium" v-model="applyForm.applyRemark" disabled></el-input>
        </el-form-item>
        <el-divider content-position="left">{{$t('checkInfo')}}</el-divider>
        <el-form-item :label="$t('checkStatus')" prop="status">
          <el-radio v-model="checkForm.status" :label="1" border>{{$t('getApproved')}}</el-radio>
          <el-radio v-model="checkForm.status" :label="2" border>{{$t('notApproved')}}</el-radio>
        </el-form-item>
        <el-form-item :label="$t('checkRemark')" prop="remark">
          <el-input type="textarea" size="medium" v-model="checkForm.remark"></el-input>
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmitCheckResult('checkForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>

import orgInfoApply from '@/api/orgInfoApply'
import org from '@/api/org'
export default {
  name: "virtualAccountRechargeCheck",
  data () {
    return {
      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      queryForm: {},
      //是否显示审核申请弹框
      isCheckApply: false,
      //弹框标题  
      applyTitle: "transferRechargeApply",

      //申请表单
      applyForm: {},
      //审核表单
      checkForm: {},
      orgList: [],
    }
  },
  computed: {
    rules () {
      return {
        status: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;
      orgInfoApply.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    openCheckApply (row) {
      this.isCheckApply = true;
      this.applyForm = row;
      this.checkForm = {
        applyId: row.id,
        status: row.status,
        remark: row.verifyRemark
      }

      if (row.type == 0) this.applyTitle = "transferRechargeApply";
      else if (row.type == 1) this.applyTitle = "cashRechargeApply";
      else if (row.type == 2) this.applyTitle = "withdrawDepositApply";
    },

    /**提交充值申请 */
    onSubmitCheckResult (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        orgInfoApply.disposeOrgInfoApply({
          param: _this[refName],
          success: res => {
            _this.isLoading = false;
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.isCheckApply = false;
              _this.getList();
            }
            else
              _this.$message.warning(_this.$t(res.msg));
          }
        })
      })
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
  },
  created () {
    this.getList();
    if (this.loginInfo.orgType == 1) {
      this.getOrgList();
    }
  }
}
</script>

<style lang="less" scoped>
.recharge-apply {
  #org-info-from {
    .el-col-8 {
      margin-bottom: 31px;
    }
  }

  .testes {
    animation: identifier 300ms;
  }
  @keyframes identifier {
    0% {
      height: 0px;
    }
    100% {
      height: 100px;
    }
  }
}
</style>